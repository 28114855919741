<template>
  <div>
    <van-nav-bar
      class="backBox"
      title="办理长租"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="page">
      <div class="mainWrap">
        <!-- TODO暂时隐藏 -->
        <!-- <div class="location_box">

        <div class="location_tit">
          <van-icon name="location-o" color="#4882FC" />
          <span class="location_name">杭州滨江天街</span>
        </div>
        <span class="modify_text">修改</span>
      </div> -->
        <div class="plate_num_box">
          <!-- <h3 style="text-align: left">请输入车牌号</h3> -->
          <div class="plate_num_box_tit" style="text-align: left">
            请输入车牌号
          </div>

          <div class="plate_num_box_input">
            <div
              class="plate_wrap"
              v-for="(item, index) in plateNumber"
              :key="index"
              @click="bindFocus(index)"
            >
              <div
                class="plate_wrap_input"
                v-if="index !== 7"
                :class="[input_index === index ? ' section-dashed' : '']"
              >
                {{ item }}
              </div>
              <!-- 2 -->
              <div
                class="plate_wrap_input new_input"
                v-if="index === 7"
                :class="[input_index == index ? ' section-dashed' : '']"
              >
                <div v-if="newFlag" placeholder="">
                  {{ item }}
                </div>
                <img
                  v-if="!newFlag"
                  src="../../../assets/img/indexnewnengyuan@2x.png"
                  alt=""
                />

                <span v-if="!newFlag" class="new_vh">新能源</span>
              </div>
            </div>
          </div>
        </div>

        <div class="month_type_box">
          <van-radio-group v-model="radioSelVal">
            <div class="month_type">租赁类型</div>
            <div class="" v-for="item in monthTypeList" :key="item.id">
              <div class="month_item">
                <div class="month_item_tit">
                  <span class="month_item_tit_name">{{ item.name }}</span>
                  <span class="month_item_tit_date"
                    >生效时间：{{ item.comment }}</span
                  >
                </div>
                <div class="month_money">
                  <span class="month_money_text"> {{ item?.default_rent_list?.[0]?.month_rent ? item.default_rent_list[0].month_rent / 100 : 0 }}元 </span>
                  <!-- <span class="month_money_text"> {{ 0 }}元 </span> -->
                  <label>
                    <van-radio
                      :name="item.id"
                      @click="radioBtn(item)"
                    ></van-radio>
                  </label>
                </div>
              </div>
              <div class="lineline"></div>
            </div>
          </van-radio-group>
        </div>

        <div class="payNumBox">
          <div class="pay_num_tit">
            <span class="pay_num_text">购买数量</span>
            <van-stepper v-model="monthNum" @change="onChangeStep" />
          </div>
          <div class="lineline"></div>
        </div>

        <div class="tip_box">
          <span class="tip_box_tit">温馨提示：</span>
          <span class="tip_box_text"
            >1.套餐显示为单个车位的价格，支付总金额按照本次办理的约束<br />或者周数叠加计算</span
          >
        </div>
        <div class="footer_box">
          <div class="agree_box">
            <van-checkbox
              class="agree_box_checkbox"
              v-model="agreeChecked"
              @change="onChangeCheckBox"
            ></van-checkbox>

            <div class="agree_box_tit">
              <span class="agree_box_tit_text">已经阅读并同意</span>
              <span class="agree_box_tit_name" @click="view_policy"
                >《地下停车场车位租赁服务协议》</span
              >
            </div>
          </div>
          <div class="lineline"></div>
          <div class="pay_box">
            <div class="pay_box_wrap">
              <span class="pay_box_all">合计：</span>
              <span class="pay_box_all_money">{{
                waitPayMoney.toFixed(2)
              }}</span>
              <span class="pay_box_all_last">元</span>
            </div>

            <van-button
              class="pay_button"
              type="info"
              color="##4470FC"
              @click="onClickPay"
              :loading="payBtnLoading" loading-text="加载中..."
              >支付</van-button
            >
          </div>
        </div>
      </div>

      <!-- 键盘 -->
      <car-keyboard
        :show.sync="showKeyboard"
        @getResult="setNumber"
        :plateNumber.sync="plateNumber"
        :index.sync="input_index"
        @setIndex="setIndex"
      ></car-keyboard>

      <!-- 隐私弹窗 -->
      <privacy-policy
        :show="wang_show_privacy_policy"
        @get-cancel="getPolicyCancel"
        @get-success="getPolicySuccess"
        @get-polocy-show="getPolicyShow"
      ></privacy-policy>
    </div>
  </div>
</template>
<script>
import CarKeyboard from '../../../components/CarKeyboard.vue'
import PrivacyPolicy from '../../../components/PrivacyPolicy.vue'

import { Toast, Dialog } from 'vant'
import * as api from '../../../api/api'
import * as constant from '../../../api/constant'
import * as common from '../../../api/common'
import * as util from '../../../api/util'
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
export default {
  components: { CarKeyboard, PrivacyPolicy },
  data () {
    return {
      showKeyboard: false,
      plateNumber: ['', '', '', '', '', '', '', ''], // 键盘操作结果值
      plateNumberBak: ['', '', '', '', '', '', '', ''],
      input_index: 0,
      newFlag: false,
      index: '',
      monthTypeList: [

        // {
        //   id: 1,
        //   value: 1,
        //   name: '无限制包月卡',
        //   date: '全时段'

        // },
        // {
        //   id: 2,
        //   value: 2,
        //   name: '工作日白天包月卡',
        //   date: '工作日（周一至周五）08:00至17:00'
        // },
        // {
        //   id: 3,
        //   value: 99,
        //   name: '夜间包月卡',
        //   date: '22:00至次日09:00'
        // }
      ],
      radioSelVal: null,
      privacy_policy_checked: false,
      wang_show_privacy_policy: false,
      agreeChecked: false,
      monthNum: 1,
      waitPayMoney: 0.00,
      dateRule: constant.dateRule,
      isVueReady: false,
      isTypeReady: false,
      isPress: false,
      code: '',
      appidLocal: '',
      sidLocal: 'yuen03',
      payBtnLoading: false,
      theRent: 0,
      payTypeLocal: '',
      tempBack: {
        expireMillSeconds: 240 * 1000,
        timeout: null,
        timestamp: 0
      }

    }
  },
  mounted () {
    this.isVueReady = true
    console.log('当前介质是微信还是支付宝', common.getUserAgent())
    this.setButtonClertload()
    if (common.getUserAgent() === common.userAgentInfo.wx) {
      this.WX_Bridge_ready(() => {
        this.setButtonClertload()
      })
    } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
      this.ZFBReady(() => {
        this.setButtonClertload()
      })
    }
    // 判断是否需要进行支付
    const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
    const monthTypeListLocal = JSON.parse(sessionStorage.getItem(constant.keyMonthTypeListSession))
    // const payTypeLocal = sessionStorage.getItem(constant.keyPayTypeSession)

    if (monthTypeListLocal) {
      this.monthTypeList = monthTypeListLocal
    }
    const comparePayType = Number(this.thePayType)

    const needCode = (comparePayType === common.payType.wx || comparePayType === common.payType.zfb || comparePayType === common.payType.abc)
    // TODO 点击支付获取code后会重新来到该页面 走挂载，点击支付后需要设置是否点击了支付按钮的标志，挂载中判断是否已经点击，
    // todo 已经点击，且有code，则直接访问支付接口获取支付回调地址。否则不执行
    if (sessionStorageLocal && monthTypeListLocal && needCode) {
      // this.$dialog.alert({
      //   // title: this.code,
      //   title: this.$route.query.code,
      //   message: JSON.stringify(this.$route.query)
      // }).then(() => {

      // })
      // https://paycloud.test.szzcloud.club/?code=021h4Kll2kCh5e49YPll2i3rYL3h4KlW&state=yuen03#/monthlyRental/MonthlyRentLong
      // this.payTypeLocal = payTypeLocal
      const urlParams = new URLSearchParams(window.location.search)
      this.code = urlParams.get('code') || this.$route.query.code

      // this.code = this.$route.query.code

      // 数据回显
      // this.plateNumber = sessionStorageLocal.plate_number.split('')
      this.agreeChecked = true
      this.clickHistoryNum(sessionStorageLocal.plate_number)
      this.radioSelVal = Number(sessionStorageLocal.vh_cate_id)
      this.monthNum = Number(sessionStorageLocal.month)
      this.waitPayMoney = Number(sessionStorageLocal.monthly_rent_fee) / 100 * Number(sessionStorageLocal.month)
      if (this.code) {
        this.$dialog.alert({
          title: '打印当前URL',
          message: window.location.href
        }).then(() => {
        })
        // TODO 已经点击支付且code成功拿回 可以请求支付接口
        Toast.loading({
          forbidClick: true,
          message: '请稍候...'
        })
        this.doPay()
      } else {
        this.$dialog.alert({
          title: '支付失败'
        }).then(() => {
          this.setDataWhenErrorConfirm()
        })
        // this.getAllCode()
      }
      return
    }
    this.$nextTick(() => {
      this.getCanRentCateList()
      this.isVueReady = true
      this.getTempBackTimeout()
      if (!this.vhColumns || this.vhColumns.length === 0) {
        Dialog.confirm({
          title: '提示',
          message: '您还没有绑定车辆，是否前往绑定？',
          confirmButtonColor: '#4797EA'
        })
          .then(() => {
          // on confirm
            this.$router.push('/myCar')
            // const parkName = localStorage.getItem('park_name')
            // if (!parkName) {
            //   // 重新http获取
            //   this.query_customer_by_sid()
            // }
            // this.parkName = parkName
            // const vhColumns = JSON.parse(JSON.stringify(this.vhColumns))
            // console.log('vhColumns:', vhColumns)
            // // vhColumns.unshift('浙A40001')
            // // vhColumns.unshift('浙AQW001')
            // // vhColumns.unshift('浙ALG101')
            // vhColumns.unshift('全部')
            // this.vhColumnsLocal = vhColumns
            // console.log('vhColumnsLocal:', this.vhColumnsLocal)

            // if (!this.selVhVal || this.selVhVal === '') {
            //   this.selVhVal = this.vhColumnsLocal[0]
            //   // this.selVhVal = '全部'
            // }
            // this.queryParkingRecord()
          })
          .catch(() => {
          // on cancel
          // TODO 开发测试构造

          })
      } else {
        console.log('当前已绑定的全部车辆', this.vhColumns)
        // 取第一个直接填充到输入框
        // if (!this.selVhVal || this.selVhVal === '') {
        //   this.selVhVal = this.vhColumnsLocal[0]
        //   // this.selVhVal = '全部'
        // }
        // this.queryParkingRecord()
        this.isVueReady = true
        console.log('当前介质是微信还是支付宝', common.getUserAgent())
        if (common.getUserAgent() === common.userAgentInfo.wx) {
          this.WX_Bridge_ready(() => {
            this.setButtonClertload()
          })
        } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
          this.ZFBReady(() => {
            this.setButtonClertload()
          })
        }
        // if (this.monthTypeList.length > 0) {
        // this.radioSelVal = this.monthTypeList[0].id
        // this.countMoney(this.monthTypeList[0], this.monthNum)
        // }
      }
    })
  },
  computed: {
    ...mapGetters(['vhColumns', 'openID', 'thePayType']),
    ...mapState({
      // payType: state => state.payType.payType,
      sid: state => state.sid.sid,
      appid: state => state.appid.appid
    })
  },
  created () {
    // if (common.getUserAgent() === common.userAgentInfo.wx) {
    //   this.code = this.$route.query.code
    // }
  },
  beforeRouteLeave (to, from, next) {
    // 调用方法清除 session 数据
    if (to.path === '/monthlyRental/index') {
      this.clearSessionData()
    }
    // 继续路由导航
    next()
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.path === '/monthlyRental/index') {
        vm.clearSessionData()
      }
    })
  },
  // beforeDestroy () {
  //   this.clearSessionData()
  // },
  // destroyed () {
  //   this.clearSessionData()
  // },

  methods: {
    getTempBackTimeout () {
      const ms = this.tempBack.expireMillSeconds
      if (!this.tempBack.timestamp) this.tempBack.timestamp = new Date().getTime()
      // console.log('tempBack.timestamp', this.tempBack.timestamp)
      this.tempBack.timeout = setTimeout(() => {
        this.expireGoBack()
        this.clearBackTimeout()
      }, ms)
    },
    clearBackTimeout () {
      clearTimeout(this.tempBack.timeout)
      this.tempBack.timeout = null
    },
    expireGoBack () {
      this.tempBack.timestamp = 0
      this.$dialog.alert({
        title: '支付超时',
        message: '点击“确认”后重新付款'
      }).then(() => {
        this.setDataWhenErrorConfirm()
      })
    },
    clearSessionData () {
      sessionStorage.removeItem(constant.keyBusinessInfoSession)
      sessionStorage.removeItem(constant.keyMonthTypeListSession)
      // sessionStorage.removeItem(constant.keyPayTypeSession)
    },
    doPay () {
      const that = this
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })

      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
      const grossPrice = parseFloat(this.waitPayMoney) * 100
      const actuallyPaid = parseFloat(this.waitPayMoney) * 100
      const subject = sessionStorageLocal.plate_number + sessionStorageLocal.vh_cate_name + '服务办理'
      // TODO
      common.getPayargsMonth(this.sid, grossPrice, actuallyPaid, Number(this.thePayType), subject, sessionStorageLocal, this.code)
        .then(payargs => {
          // that.$toast.clear()
          that.isPress = false
          console.log('getPayargsMonth----', payargs)
          if (!payargs) {
            that.$dialog.alert({
              title: '支付失败'
              // title: '支付失败 dopay 01'
            }).then(() => {
              that.setDataWhenErrorConfirm()
            })
            return
          }
          if (common.getUserAgent() === common.userAgentInfo.wx) {
            that.wxReadyToPay(payargs)
          } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
            that.do_zfb_pay(payargs)
          }
        })
        .catch(e => {
          that.$toast.clear()
          that.isPress = false
          if (parseFloat(grossPrice) > 0) {
            console.log(e)
            // TODO 农行报错
            that.$dialog.alert({
              // showCancelButton: true,
              title: '支付失败',
              message: e || ''
            }).then(() => {
              // that.onClickLeft()
              that.setDataWhenErrorConfirm()
            })
          } else that.$toast.clear()
        })
    },
    wxReadyToPay (payargs) {
      console.log('wxReadyToPay---', payargs)
      var that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          // console.log('vv 111')
          document.addEventListener('WeixinJSBridgeReady', that.onBridgeReady(payargs), false)
        } else if (document.attachEvent) {
          // console.log('vv 222')
          document.attachEvent('WeixinJSBridgeReady', that.onBridgeReady(payargs))
          document.attachEvent('onWeixinJSBridgeReady', that.onBridgeReady(payargs))
        }
      } else {
        // console.log('vv 333')
        that.onBridgeReady(payargs)
      }
    },
    onBridgeReady (payargs) {
      console.log('onBridgeReady---', payargs)
      console.log('onBridgeReady---', this.thePayType, typeof this.thePayType)
      var that = this
      // return
      const payType = Number(this.thePayType)
      if (payType === common.payType.wx) { // 微信直联
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.bjyz) { // 微信云纵间联
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   invoice_url: payargs.invoice_url,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }
        // this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.trade_url)
        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.pp) { // 微信PP间联
        console.log('wx pp', payargs)
        // TODO 保存月租相关的支付完成信息  等支付完成跳转展示。如果支付平台没有支付完成展示页面则需要展示
        const pars = {
          start_time: this.start_time,
          actually_paid: this.actually_paid,
          couponPrice1: this.couponPrice1,
          sid: this.sid,
          parkName: this.parkName,
          plateNumber: this.plate_number
        }

        this.$store.commit(constant.keyPayMonthCompleteInfoVuex, pars)

        this.isPress = true
        this.payBtnLoading = false
        window.location.replace(payargs.pay_url)
        // pay_url: "https://auth.4pyun.com/authorize?scope=snsapi_base&redirect_uri=https%3A%2F%2Fapp.4pyun.com%2Fpayment%2Ftrade%2Fcreate%3Fpay_id%3D983768955900399617"

        //            window.location.href = payargs.trade_url;
      } else if (payType === common.payType.abc) { // 微信农行间联
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true

        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            appId: payargs.appId != null ? payargs.appId : that.appid, // 公众号名称，由商户传入
            timeStamp: payargs.timeStamp || payargs.timestamp, // 时间戳，自1970年以来的秒数
            nonceStr: payargs.nonceStr || payargs.nonce_str, // 随机串
            package: payargs.package,
            signType: payargs.signType != null ? payargs.signType : 'MD5', // 微信签名方式：
            paySign: payargs.paySign || payargs.sign // 微信签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // that.$toast.success('支付完成')
              // // that.$router.replace('/index')
              // that.$router.replace({
              //   path: '/index',
              //   query:{
              //     sid: this.$Storejs.getItem('sid'),
              //     channel_path: this.$Storejs.getItem('channel_path')
              //   }
              // })
              // that.$toast({
              //   type: 'success',
              //   message: '支付完成',
              //   duration: 0,
              //   forbidClick: true
              // })
              window.location.replace('https://pa03.yuntingiot.com/api/ac/477786592149311488')
              // WeixinJSBridge.call('closeWindow')
            } else {
              that.$dialog.alert({
                // showCancelButton: true,
                title: '支付失败'
              }).then(() => {
                // that.onClickLeft()
                that.setDataWhenErrorConfirm()
              })
            }
          })
      } else if (payType === common.payType.lanzhoubank) { // 兰州银行微信
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // that.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.billQRCode)
      } else if (payType === common.payType.ningbobank) { // 宁波银行微信
        // const pars = {
        //   start_time: this.start_time,
        //   hour: this.hour,
        //   min: this.min,
        //   seconds: this.seconds,
        //   actually_paid: this.actually_paid,
        //   couponPrice1: this.couponPrice1,
        //   sid: this.sid,
        //   parkName: this.parkName,
        //   plateNumber: this.plate_number
        // }

        // this.$store.commit(constant.keyAdverInfoVuex, pars)

        this.isPress = true
        window.location.replace(payargs.pay_url)
      }
    },
    WX_Bridge_ready (cb) {
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', cb(), false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', cb())
          document.attachEvent('onWeixinJSBridgeReady', cb())
        }
      } else {
        cb()
      }
    },
    setButtonClertload () {
      this.$nextTick(() => {
        this.isTypeReady = true
      })
    },
    // 获取可包月车类别列表
    async getCanRentCateList () {
      try {
        const { data } = await axios({
          url: common.iotUrl + '/uuid/' + api.urlList.car_owner.vh.get_vh_cate_and_rent,
          method: 'POST',
          data: {
            sid: this.sid || this.$route.query.sid,
            // sid: this.sidLocal,
            openid: this.openID,
            // openid: 'o1pxwuMcjjXt2YtCXSl3UC-ZbK-w',
            open_handle_vh_cate: 1, // 是否指定长租办理的车辆类别(8、9、10)，指定=1；不指定=0
            version: 'v2'
          },
          header: {
            'content-type': 'application/json' // 默认值
          }
        })
        console.log('openid res', data)

        if (data.code === 10000) {
          const localList = data.list
          localList.forEach((x, xindex) => {
            this.dateRule.forEach((y, yindex) => {
              if (xindex === yindex) {
                x.comment = y
              }
            })
          })
          // 全时段 工作日（周一至周五）08:00至17:00 22:00至次日09:00
          console.log('localist', localList)
          this.monthTypeList = data.list
          if (this.monthTypeList.length > 0) {
            this.radioSelVal = Number(this.monthTypeList[0].vh_cate_id)
            this.countMoney(this.monthTypeList[0], this.monthNum)
          } else {
            this.$dialog.alert({
              title: '暂无可包月类别',
              message: '请联系管理员或者车场主'
            }).then(() => {
              this.onClickLeft()
            })
          }
        } else {
          Toast.fail('查询出错')
        }
      } catch (e) {
        this.$toast('查询异常')
      }
    },
    // 计算需支付总金额
    countMoney (data, num) {
      console.log('data', data)
      console.log('num', num)
      const rent = data?.default_rent_list?.[0]?.month_rent ? data.default_rent_list[0].month_rent / 100 : 0
      this.theRent = rent
      this.waitPayMoney = rent * num
    },
    // 购买数量
    onChangeStep () {
      console.log('购买数量', this.monthNum, this.radioSelVal)
      const selectedObject = this.monthTypeList.find(item => item.id === Number(this.radioSelVal))
      console.log('selectedObject', selectedObject)
      this.countMoney(selectedObject, this.monthNum)
    },
    async onClickPay () {
      // 检测车牌号码
      const plateNum = this.plateNumber.join('')
      if (plateNum.length !== 7 && plateNum.length !== 8) {
        return Toast.fail('请输入正确的车牌号码')
      }
      if (!this.radioSelVal) {
        return Toast.fail('请先选择月租类型')
      }
      if (!this.agreeChecked) {
        return Toast.fail('请先同意隐私协议')
      }
      // 检查sid

      if (this.waitPayMoney <= 0) {
        return Toast.fail('应付金额为0')
      }
      if (!this.sid) {
        return Toast.fail('车场编号丢失，请返回重试')
      }

      if (!this.isVueReady) {
        return this.$toast('努力加载中.')
      } else if (!this.isTypeReady) {
        if (common.getUserAgent() === common.userAgentInfo.wx) {
          return this.$toast('微信努力加载中.')
        } else {
          return this.$toast('支付宝努力加载中.')
        }
      }
      this.payBtnLoading = true
      Toast.loading({
        forbidClick: true,
        message: '请稍候...'
      })
      // let payWay = common.payWay.weichat
      // if (common.getUserAgent() === 'wx') {
      //   payWay = common.payWay.weichat
      // } else if (common.getUserAgent() === 'zfb') {
      //   payWay = common.payWay.alipay
      // }
      const businessInfo = {
        business_type: constant.monthRentInfos.businessType,
        charge_type: constant.monthRentInfos.chargeType.vhRegister,
        plate_number: plateNum,
        vh_cate_id: this.radioSelVal,
        vh_type_id: 1,
        month: this.monthNum,
        charge_start_time: common.format(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        monthly_rent_fee: this.theRent * 100
      }
      sessionStorage.setItem(constant.keyBusinessInfoSession, JSON.stringify(businessInfo))
      sessionStorage.setItem(constant.keyMonthTypeListSession, JSON.stringify(this.monthTypeList))
      // sessionStorage.setItem(constant.keyPayTypeSession, this.thePayType)
      const comparePayType = Number(this.thePayType)
      console.log('支付 类型', this.thePayType, typeof this.thePayType)
      console.log('支付 类型', comparePayType, typeof comparePayType)
      // 微信或支付宝 code, type=1、2、41 时必须获取上传 code
      if (comparePayType === common.payType.wx || comparePayType === common.payType.zfb || comparePayType === common.payType.abc) {
        // 需要code
        // 获取code
        console.log('当前需要code。。。')
        this.getAllCode()
      } else {
        this.code = null
        this.doPay()
      }
    },
    getAllCode () {
      if (common.getUserAgent() === common.userAgentInfo.wx) {
        console.log('wx getcode')
        this.getAppId().then(appid => {
          window.location.href = common.getCodeObtainUrl(window.location, appid, true)
          console.log('window.location.href', window.location.href)
        }).catch(e => {
          this.$toast('网络错误')
        })
      } else if (common.getUserAgent() === common.userAgentInfo.zfb) {
        if (!this.code) {
          this.getZFBAppId().then(appid => {
            window.location.href = common.getCodeObtainUrl(window.location, appid, false)
          }).catch(e => {
            this.$toast('网络错误')
          })
        }
      }
      // console.log('22222222222222')
    },
    async getAppId () {
      return axios({
        url: common.cloudParkingUrl + '/s2p/' + api.urlList.query_wx_appid,
        method: 'POST',
        data: {
          sid: this.sid || this.$route.query.sid
          // sid: this.sidLocal
        },
        header: {
          'content-type': 'application/json' // 默认值
        }
      }).then(res => {
        const d = res && res.data
        if (d.code === 10000) {
          // console.log('getAppId', d)
          this.appidLocal = d.app_id
          // this.$store.commit(constant.keyAppIDVuex, d.app_id)
          return this.appidLocal
        } else {
          return Promise.reject(new Error())
        }
      })
    },
    // 切换月租类型
    radioBtn (item) {
      console.log('当前选择的是哪个月租服务', this.radioSelVal, item)

      this.countMoney(item, this.monthNum)
    },
    onChangeCheckBox () {
      console.log('agreeChecked', this.agreeChecked)
    },
    doQuery () {

    },
    bindFocus (index) {
      if (!this.showKeyboard) {
        this.showKeyboard = true
        this.input_index = Number(index)
      } else {
        this.input_index = Number(index)
      }
    },
    setNumber (data) {
      if (data[7] === '') {
        this.newFlag = false
      } else {
        this.newFlag = true
      }
    },
    setIndex (data) {
    },
    clickHistoryNum (data) {
      console.log('点击的历史记录车牌为', data)
      console.log('正在展示的车牌为', this.plateNumber)
      const plateNum = data
      // 如果点击的车牌号和输入框的不一样 则填入  否则 什么也不做
      const inpNum = this.plateNumber.join('')
      if (plateNum === inpNum) {
        return
      }
      const nowArr = plateNum.split('')
      console.log(nowArr)

      const bakArr = JSON.parse(JSON.stringify(this.plateNumberBak))
      console.log(bakArr)
      for (let i = 0; i < nowArr.length; i++) {
        if (nowArr[i] !== '') {
          bakArr[i] = nowArr[i]
        }
      }
      this.plateNumber = bakArr
      console.log('daa', this.plateNumber)
      this.setNumber(this.plateNumber)
      // console.log('整理后的车牌号', bakArr)
      // this.setData({
      //   plateNumber: bakArr,
      //   plateNumQry: plateNum,
      //   historyFlag: true
      // })
      // const that = this
      // is_show(that, plateNum)
      // this.inputKeys(plateNum, 'inputChange')
      // console.log('点击历史后当前车牌字符串', this.data.plateNumQry)
    },
    view_policy () {
      console.log('查看隐私政策')
      this.wang_show_privacy_policy = true
    },
    getPolicyCancel (data) {
      this.wang_show_privacy_policy = false
      this.agreeChecked = false
    },
    getPolicySuccess (data) {
      this.wang_show_privacy_policy = false
      this.agreeChecked = true
    },
    getPolicyShow () {
      this.wang_show_privacy_policy = false
    },
    onClickLeft () {
      // this.$router.go(-1)
      this.$router.replace('/monthlyRental/index')
    },
    setDataWhenErrorConfirm () {
      this.payBtnLoading = false
      const sessionStorageLocal = JSON.parse(sessionStorage.getItem(constant.keyBusinessInfoSession))
      const monthTypeListLocal = JSON.parse(sessionStorage.getItem(constant.keyMonthTypeListSession))
      if (monthTypeListLocal) {
        this.monthTypeList = monthTypeListLocal
      }
      console.log('获取到的code：', this.code)
      console.log('获取到的code：', this.$route.query.code)
      // TODO 点击支付获取code后会重新来到该页面 走挂载，点击支付后需要设置是否点击了支付按钮的标志，挂载中判断是否已经点击，
      // todo 已经点击，且有code，则直接访问支付接口获取支付回调地址。否则不执行
      if (sessionStorageLocal && monthTypeListLocal) {
      // 数据回显
      // this.plateNumber = sessionStorageLocal.plate_number.split('')
        this.clickHistoryNum(sessionStorageLocal.plate_number)
        this.radioSelVal = Number(sessionStorageLocal.vh_cate_id)
        this.monthNum = Number(sessionStorageLocal.month)
        this.waitPayMoney = Number(sessionStorageLocal.monthly_rent_fee) / 100 * Number(sessionStorageLocal.month)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .location_box {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.08rem;
    .location_tit {
      // width: 5.5625rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .van-icon {
        font-size: 0.14rem;
        line-height: 0.14rem;
      }
      .location_name {
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 0.12rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.15rem;
      }
    }
    .modify_text {
      overflow-wrap: break-word;
      color: rgba(72, 130, 252, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.17rem;
      margin-left: 0.04rem;
    }
  }
  .plate_num_box {
    width: 90%;
    // height: 12.5rem;
    background-color: #fff;
    box-shadow: 0px 0.01rem 0.05rem 0px rgba(68, 112, 252, 0.37);
    background-color: rgba(253, 254, 255, 1);
    border-radius: 0.08rem;
    margin-left: 0.01rem;
    display: flex;
    flex-direction: column;
    // padding: 0 0 1.0625rem;
    padding: 0.08rem;
    margin-top: 0.16rem;
    .plate_num_box_tit {
      font-size: 0.16rem;
      text-align: left;
      font-weight: bolder;
      margin: 0.09rem;
    }
    .plate_num_box_input {
      width: 3.24rem;
      margin-top: 0.11rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0 0.08rem 0 0.08rem;
      .plate_wrap {
        // width: 2.125rem;
        width: 0.34rem;
        height: 0.44rem;
        background-color: rgba(233, 238, 255, 1);
        display: flex;
        align-items: center;
        text-align: center;
        .section-dashed {
          height: 0.44rem;
          border: 0.01rem solid #1989fa;
        }

        .plate_wrap_input {
          height: 0.44rem;
          width: 0.34rem;
          text-align: center;
          font-size: 0.16rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .new_vh {
            overflow-wrap: break-word;
            color: rgba(0, 0, 0, 1);
            font-size: 0.1rem;
            font-family: PingFangSC-Regular;
            font-weight: normal;
            text-align: left;
            white-space: nowrap;
            line-height: 0.14rem;
            margin: 0.01rem 0 0 -0.01rem;
          }
        }
        .new_input {
          background-color: rgba(233, 255, 235, 1);
          img {
            width: 0.18rem;
            height: 0.17rem;
            margin: 0 0.06rem 0 0.05rem;
          }
        }
      }
    }
    .history {
      margin-top: 0.12rem;
      display: flex;
      justify-content: flex-start;
      // flex-wrap: wrap;
      align-items: center;
      .historyIcon {
        margin-left: 0.08rem;
        margin-bottom: 0.08rem;
      }
      .historyItem {
        background-color: #f4f4f4;
        padding: 0.05rem 0.09rem;
        border-radius: 0.12rem;
        font-size: 0.1rem;
        color: #333;

        margin-right: 0.04rem;
        margin-left: 0.08rem;
        margin-bottom: 0.08rem;
      }
    }
    .query_btn {
      background-color: rgba(68, 112, 252, 1);
      border-radius: 0.04rem;
      margin-top: 0.12rem;
      display: flex;
      flex-direction: column;
      padding: 0.08rem 1.21rem 0.08rem 1.21rem;
      .query_btn_text {
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.22rem;
      }
    }
  }
  .month_type_box {
    width: 3.43rem;
    align-self: center;
    margin-top: 0.14rem;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    .month_type {
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 1);
      font-size: 0.16rem;
      font-family: PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      white-space: nowrap;
      line-height: 0.22rem;
      margin: 0 0.23rem 0 0.09rem;
    }
    .month_item {
      width: 3.43rem;
      align-self: center;
      margin-top: 0.14px;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      .month_item_tit {
        display: flex;
        flex-direction: column;
        .month_item_tit_type {
        }
        .month_item_tit_name {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.2rem;
          margin: 0.09rem 0.12rem 0 0;
        }
        .month_item_tit_date {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
          margin-top: 0.02rem;
        }
      }
      .month_money {
        // width: 4.6875rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.31rem 0 0.19rem 0;
        .month_money_text {
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 1);
          font-size: 0.14rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.14rem;
          margin-right: 0.02rem;
        }
      }
    }
  }
  .payNumBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pay_num_tit {
      width: 3.43rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.04rem 0.1rem 0.04rem 0.1rem;
      .pay_num_text {
        font-size: 0.12rem;
        color: #999999;
      }
    }
  }
  .tip_box {
    display: flex;
    flex-direction: column;
    margin: 0.1rem 0.27rem 0 0.16rem;
    .tip_box_tit {
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      white-space: nowrap;
      line-height: 0.17rem;
      margin-right: 2.77rem;
    }
    .tip_box_text {
      width: 3.32rem;
      height: 0.34rem;
      overflow-wrap: break-word;
      color: rgba(102, 102, 102, 1);
      font-size: 0.12rem;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      text-align: left;
      line-height: 0.17rem;
      margin-top: 0.08rem;
    }
  }
  .footer_box {
    box-shadow: 0 -0.02rem 0.02rem 0 rgba(239, 239, 239, 0.5);
    background-color: rgba(255, 255, 255, 1);
    margin-top: 0.08rem;
    display: flex;
    flex-direction: column;
    padding: 0.09rem 0 0.08rem 0;
    .agree_box {
      width: 2.64rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0.95rem 0.1rem 0.16rem;
      // /deep/ .agree_box_checkbox{
      //   font-size: 0.2rem !important;
      // }
      /deep/ .van-checkbox__icon,
      .van-checkbox__icon--round {
        font-size: 0.2rem !important;
      }
      .agree_box_tit {
        width: 2.42rem;
        height: 0.16rem;
        overflow-wrap: break-word;
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        line-height: 0.16rem;

        .agree_box_tit_text {
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 0.11rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.16rem;
          margin-left: 0.06rem;
        }
        .agree_box_tit_name {
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.11rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.16rem;
        }
      }
    }
    .pay_box {
      // width: 13.875rem;
      // flex-direction: row;
      display: flex;
      justify-content: flex-end;
      margin-top: 0.08rem;
      .pay_box_wrap {
        // width: 5.4375rem;
        height: 0.25rem;
        overflow-wrap: break-word;
        font-size: 0.11rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        line-height: 0.17rem;
        margin-top: 0.21rem;
        margin-right: 0.16rem;
        .pay_box_all {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
        }
        .pay_box_all_money {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(68, 112, 252, 1);
          font-size: 0.18rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          white-space: nowrap;
          line-height: 0.17rem;
        }
        .pay_box_all_last {
          height: 0.25rem;
          overflow-wrap: break-word;
          color: rgba(102, 102, 102, 1);
          font-size: 0.12rem;
          font-family: PingFangSC-Regular;
          font-weight: normal;
          text-align: left;
          line-height: 0.17rem;
        }
      }
      .pay_button {
        // background-color: rgba(68, 112, 252, 1);
        border-radius: 0.04rem;
        display: flex;
        flex-direction: column;
        padding: 0.11rem 0.43rem 0.11rem 0.43rem;
        margin-right: 0.16rem;
      }
    }
  }
}

.lineline {
  width: 3.43rem;
  height: 0.01rem;
  background-color: #f4f4f4;
}
</style>
